import {combineReducers} from "redux"
import earningHistory from "./earningHistory"
import workload from "./workload"

const statisticsReducer = combineReducers({
        earningHistory,
        workload
    }
)

export default statisticsReducer