import useJwt from '../../../services/auth/jwt/useJwt'
import { AUTH_LOGIN_ACTION, AUTH_LOGOUT_ACTION } from '../../constants/actions'

// ** Handle User Login
export const handleLogin = data => {

    return dispatch => {

        const { user, token, rememberMe } = data

        const [role] = user.roles

        const userData = {
            id: user.id,
            email: user.email,
            username: `${user.first_name} ${user.last_name}`,
            first_name: user.first_name,
            last_name: user.last_name,
            role: role.name || 'Anonimous',
            avatar: user.photo_url,
            phone_code: user.phone_code,
            phone_number: user.phone_number
        }

        dispatch({
            type: AUTH_LOGIN_ACTION,
            userData,
            token
        })

        useJwt.setToken(token, rememberMe)

        useJwt.setUserData(userData, rememberMe)
    }
}

// ** Handle User Logout
export const handleLogout = () => {

    return dispatch => {

        dispatch({ type: AUTH_LOGOUT_ACTION })

        useJwt.removeToken()
        useJwt.removeUserData()
        useJwt.removeSubscriptionData()
    }
}
